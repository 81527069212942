import React from 'react';
// import sections
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, Link } from "react-router-dom";
import MyCalendar from '../layouts/Calender';
import {Button, Modal, ModalHeader,ModalBody,ModalFooter, FormGroup, Input, Label} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.css'
import Constantes from "./Constantes";
import Hero from '../components/sections/Hero';
import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';

class Calendario extends React.Component{
  constructor(props) {
    super(props);
  this.state={
    abierto:false,
    abiertolista:false,
    cliente:"",
      contacto:"",
      especialidad:"",
      fecha:"",
      hora:"",
      user:null,
      abierto:false,
    clienteid:"0",
    estado:"0",
    evento:"1",
    profesor:"",
    tipoentre:"",
      citas:[],
      observacion:"",
  }
  this.manejarCambio= this.manejarCambio.bind(this)
  this.save = this.save.bind(this);
}
  manejarCambio(e) {
    // Extraer la clave del estado que se va a actualizar, así como el valor
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value, error: "" })
}
async save(){
  // Codificar nuestro videojuego como JSON
  const cliente=this.state.cliente;
  const contacto=this.state.contacto;
  const especialidad=this.state.especialidad;
  const fecha=this.state.fecha;
  const observacion= this.state.observacion;
  var mifecha= new Date();
  var hora=this.state.hora+":00";
  var a= moment(fecha);
  var b = moment(mifecha);
  if(parseInt(a.diff(b,'days'))<0){
    window.alert("No se puede reservar fechas en el pasado");
    return false;
  }
  if(parseInt(a.diff(b,'days'))>6){
    window.alert("Las reservas se pueden realizar máximo 7 días a partir de la fecha actual.");
    return false;
  }
  if(contacto.length!=10){
    window.alert("La longitud del número de teléfono es incorrecta por favor ingreselo nuevamente. Recuerde que mediante este número se le informara la confirmación de su reserva");
    return false;
  }
  const profesor= this.state.profesor;
  const tipoentre= this.state.tipoentre;
  var timein="";
  var timefin="";

    if(cliente==="" && contacto==="" && especialidad==="" && fecha==="" && hora===""&& profesor===""){
      window.alert("Completar información del formulario.")
      return false;
    }else{
        const fin=hora.split(":");
        var min="00";
        var hour=fin[0];
        if(fin[1]=="00"){
          min="30"
          hour=parseInt(fin[0])+1;

        }else{
          min="00"
          hour=parseInt(fin[0])+2;
        }
        timein=hora;
        timefin=hour+":"+min;
    }

  var horainicio=timein.split(":");
  var horafin= timefin.split(":");
 if(horainicio[1]=="59"){
    horainicio[1]="00";
    horainicio[0]=parseInt(horainicio[0])+1;
    if(horainicio[0]<10){
      horainicio[0]="0"+horainicio[0]
    }
 }else{
  horainicio[1]=parseInt(horainicio[1])+1;
  if(horainicio[1]<10){
    horainicio[1]="0"+horainicio[1]
  }
}
if(horafin[1]=="00"){
  horafin[1]="59";
  horafin[0]=parseInt(horafin[0])-1;
  if(horafin[0]<10){
    horafin[0]="0"+horafin[0]
  }
}else{
  horafin[1]=parseInt(horafin[1])-1;
  if(horafin[1]<10){
    horafin[1]="0"+horafin[1]
  }
} 
var timeina=horainicio[0]+":"+horainicio[1];
var timefina= horafin[0]+":"+horafin[1];
  
    const verfcita = await fetch(`${Constantes.RUTA_API}/verificar_cita.php?fecha=${fecha}&&fechain=${timeina}&&cancha=${especialidad}&&fechafin=${timefina}`);
    const saber = await verfcita.json();
    if(!saber){
      const respuesta = await fetch(`${Constantes.RUTA_API}/guardar_cita.php?reserva=${cliente}&&cliente=${this.state.user.id}&&contacto=${contacto}&&especialidad=${especialidad}&&fecha=${fecha}&&timein=${timein}&&timefin=${timefin}&&profesor=${profesor}&&tipo=${tipoentre}&&observacion=${observacion}`);
      const exitoso = await respuesta.json();
      if (exitoso) {
         window.alert("Su reserva se guardo correctamente el día: "+this.state.fecha+" a las: "+timein+ " en la "+this.state.especialidad+"." );
         window.location.reload();
      } else {
         window.alert("No se pudo agendar su reservación. Por favor inténtelo mas tarde")
      }
      this.setState({
        abierto:!this.state.abierto})
    }else{
      window.alert("La cancha solicitada no se encuentra disponible en esta fecha y hora. Por favor realice su reserva en otro horario")
    }
  
  // ¡Y enviarlo!
  
}
  abrirModal=()=>{
    this.setState({
      abierto:!this.state.abierto,
      especialidad:"",
      fecha:"",
      hora:"",
      profesor:"",
      tipoentre:"",
      observacion:"",
    });
  }

  milista(){
    window.location.href="/reservas"
  }

  async componentWillMount(){
    let user = localStorage.getItem("user");
    user = user ? JSON.parse(user) : null;
    if(user !=null){
      this.setState({
        user:user,
        cliente:user.nombre,
        contacto:user.contacto,
      });
          const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_citas.php`);
          const citas = await respuesta.json();
          citas.forEach(element => {
            const fechahora=element.fecha.split(" ");
            const inicio=new Date(fechahora[0]+" "+element.timein+"00");
            const fin=new Date(fechahora[0]+" "+element.timefin+":00");
            let cita=null;
           
            var mhora=element.timein.split(":");
            if(element.cliente==user.id){
              cita={
                title:"Mi Reserva. Cancha: "+element.especialidad,
                start:inicio,
                end:fin,
                cliente:element.reserva,
                clienteid:element.cliente,
                id:element.id,
                contacto:element.contacto,
                estado:element.confirmada,
                especialidad:element.especialidad,
                hora:mhora[0]+":"+mhora[1],
                fecha:element.fecha,
                profesor:element.profesor,
                tipoentre:element.tipo,
                observacion:element.observacion,
              }
            
            }
            if(cita){
              this.setState({
                citas: this.state.citas.concat(cita),
              });
            }
      });
    }
  }
  render(){
    const user=window.localStorage.getItem("user");
    const modalStyle={
      position:"absolute",
      top:"0%",
      left:"50%",
      transform:"translate(-50%,0%)"
    }
    return (
      <>
       <main className="site-content">
       <Hero className="illustration-section-01" />
         <div className="btnreserva">
           <Button color="danger" onClick={(this.abrirModal)}>Reservar</Button>
            {
              user ?(<>
              <Button color="danger" style={{marginLeft:"50px"}} onClick={this.milista} > Mis Reservas</Button>
              </>):(
              <>
              </>)
            }
         </div>
        <MyCalendar/>
        <Modal isOpen={this.state.abierto} style={modalStyle}>
       <ModalHeader>
      
              <div className="text-color-rojo">
              RESERVA UN HORARIO
              </div>
              <div className="text-color-rojo">
              Costos por horario: <br/>
              De 6 am a 7:30 am $40 <br/>
              De 7:30 am a 18:00 $20 <br/>
              De 18:00 am a 23:00 $40
            </div>
          </ModalHeader>
          <ModalBody>
            {
              this.state.user ?(
                <>
               
                <FormGroup>
              <Label for="usuario">Nombre de usuario</Label>
              <Input type="text" id="cliente" onChange={(this.manejarCambio)} value={this.state.cliente} />
            </FormGroup>
            <FormGroup>
              <Label for="usuario">Número de Contacto</Label>
              <Input type="number" id="contacto" onChange={this.manejarCambio} value={this.state.contacto} />
            </FormGroup>
             <FormGroup>
              <Label for="usuario">Cancha</Label>
              <select className="form-control" style={{marginLeft:"30px"}} id="especialidad" onChange={this.manejarCambio} value={this.state.especialidad} >
              <option></option>
                <option>Cancha 1</option>
                <option>Cancha 2</option>
              </select>
            </FormGroup>
            <FormGroup>
            <Label for="usuario">Fecha</Label>
              <Input type="date" id="fecha" style={{marginLeft:"30px"}} onChange={this.manejarCambio} value={this.state.fecha} />
            
            </FormGroup>
            <FormGroup>
            <Label for="usuario">Hora</Label>
            <select className="form-control" style={{marginLeft:"30px"}} id="hora" onChange={this.manejarCambio} value={this.state.hora} >
              <option></option>
                <option>06:00</option>
                <option>07:30</option>
                <option>09:00</option>
                <option>10:30</option>
                <option>12:00</option>
                <option>13:30</option>
                <option>15:00</option>
                <option>16:30</option>
                <option>18:00</option>
                <option>19:30</option>
                <option>21:00</option>
                <option>22:30</option>
              </select>
            </FormGroup>
            
            {
              this.state.user.idrol_fk=="1" ?(
                <>
            <FormGroup>
              <Label for="usuario">Grupo de Entrenamiento</Label>
              <select className="form-control" style={{marginLeft:"30px"}} id="tipoentre" onChange={this.manejarCambio} value={this.state.tipoentre} >
              <option></option>
                <option>Azul</option>
                <option>Rojo</option>
                <option>Ejecutivo</option>
                <option>Juveniles</option>
              </select>
            </FormGroup>
            <FormGroup>
              <Label for="usuario">Profesor</Label>
              <select className="form-control" style={{marginLeft:"30px"}} id="profesor" onChange={this.manejarCambio} value={this.state.profesor} >
              <option></option>
                <option>Nombre 1</option>
                <option>Nombre 2</option>
                <option>Nombre 3</option>
              </select>
            </FormGroup>
            <FormGroup>
            <Label for="usuario">Observación</Label>
            <Input type="text" id="observacion" style={{marginLeft:"30px"}} onChange={this.manejarCambio} value={this.state.observacion} />
            </FormGroup>
            </>
              ):(
            <></>
            )}
                </>
              ):(
                <Label for="usuario">Por favor Inicia Sesión para agendar una reserva. Si no posees una cuenta crea una.</Label>
              )
            }
            
          </ModalBody>
          <ModalFooter>
            { this.state.user ? (
              <>
              {
                <>
                {this.state.user.idrol_fk=="1" &&(
                <>
                {
                this.state.evento=="1"? (
                  <></>
                ):(
                  <>
                    <Button color="danger" onClick={(this.delete)}>Eliminar</Button>
                  </>
                )}
                  
                  </>
                )}
              </>
              }
              {
                this.state.evento=="1"? (
                  <>
                  <Button color="success" onClick={(this.save)}>Agendar</Button>
                  </>
                ):(
                  <>
                  {
                    parseInt(this.state.clienteid)==this.state.user.id ?(
                      <>
                      { this.state.estado=="0" ? (
                        <>
                      {
                          this.state.user.idrol_fk!="1"? (
                            <>
                              <Button color="danger" onClick={(this.delete)}>Eliminar</Button>
                            </>
                          ):(
                            <>
                            </>
                          )}
                      </>
                      ):(
                        <></>
                      )}
                      </>
                    ):(
                      <></>
                    )
                  }
                  </>
                )
              }
              </>
            ):(
              <>
              <FormGroup>
              <Button color="success" href="/login/login">Iniciar Sesión</Button>
              <Button color="danger" href="/login/crear">Crear Cuenta</Button>
                </FormGroup>
              </>
            )
            }
              <Button color="danger" onClick={(this.abrirModal)}>Cerrar</Button>

          </ModalFooter>
        </Modal>
      </main>

      </>
    );
  }
}

export default Calendario;
  