import React from 'react';
import classNames from 'classnames';
import image from "../../../assets/images/logo.png"
const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <img
      className="imagenlogo"
      src={image}
   />
    </div>
  );
}

export default Logo;