import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Slider from '../components/layout/partials/Slider';
import Contacto from './Contacto';

const Home = () => {

  return (
    <>
    <main className="site-contenta">
     
    <FeaturesTiles/>
    <Contacto/>
    </main>
    
    </>
  );
}

export default Home;