import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Salud Dental',
    paragraph: 'Que tu prioridad este 2021 sea cuidar tu higiene dental. Creando sonrisas que cautivan. Somos Expertos y especialistas en Odontología.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h2 className="mt-0 mb-12">
                  Caries Dental
                  </h2>
                <p className="m-0">
                La caries dental es el daño que le puede ocurrir a un diente cuando las bacterias que causan caries que se encuentran en la boca producen ácidos que atacan la superficie del diente o esmalte. Esto puede resultar en un pequeño agujero en el diente, al que se le llama caries. Si la caries dental no se trata, puede causar dolor, una infección e incluso la pérdida del diente.
                  </p>
              </div>
              <div className={
                classNames(
                  'center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/entrenador3.jpeg')}
                  alt="Features split 01"
                  width={450}
                  height={450} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                
                <h2 className="mt-0 mb-12">
                  Sangrado de Encias
                  </h2>
                <p className="m-0">
                El sangrado de encías suele deberse a la acumulación de placa y sarro en la unión entre este tejido y los dientes. Los malos hábitos como no cepillarse diariamente, hacerlo con demasiada brusquedad, usar mal el hilo dental o consumir poca vitamina K, pueden contribuir a empeorarlo.
                  </p>
              </div>
              <div className={
                classNames(
                  'center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/entrenador2.jpeg')}
                  alt="Features split 02"
                  width={450}
                  height={450} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                
                <h2 className="mt-0 mb-12">
                  Bebidas Energitas
                  </h2>
                <p className="m-0">
                ✨¡Cuida la salud de tu dientes! ✨si consumes una bebida energética, procura cepillarte los dientes lo antes posible para mantener un esmalte sano 🦷👄
                  </p>
              </div>
              <div className={
                classNames(
                  'center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/entrenador1.jpeg')}
                  alt="Features split 03"
                  width={450}
                  height={450} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h2 className="mt-0 mb-10">
                  Sangrado de Encias
                  </h2>
                <p className="m-0">
                El sangrado de encías suele deberse a la acumulación de placa y sarro en la unión entre este tejido y los dientes. Los malos hábitos como no cepillarse diariamente, hacerlo con demasiada brusquedad, usar mal el hilo dental o consumir poca vitamina K, pueden contribuir a empeorarlo.
                  </p>
              </div>
              <div className={
                classNames(
                  'center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/entrenador5.jpeg')}
                  alt="Features split 02"
                  width={450}
                  height={450} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                
                <h2 className="mt-0 mb-12">
                  Bebidas Energitas
                  </h2>
                <p className="m-0">
                ✨¡Cuida la salud de tu dientes! ✨si consumes una bebida energética, procura cepillarte los dientes lo antes posible para mantener un esmalte sano 🦷👄
                  </p>
              </div>
              <div className={
                classNames(
                  'center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/entrenador4.jpeg')}
                  alt="Features split 03"
                  width={450}
                  height={450} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;