import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import Header from '../src/components/layout/Header';
import Footer from '../src/components/layout/Footer';
import Login from '../src/views/Login';

import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import steps from '../src/Chats/config/steps';
import chat_styles from '../src/Chats/config/chatstyles'
import botAvatar from '../src/assets/images/bot.png'
import './App.css'
// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Nosotros from './views/Nosotros';
import Contacto from './views/Contacto';
import Calendario from './views/Calendario';
import MisPedidos from './views/MisPedidos';
import Horario from './views/Horarios';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};


const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
    <div className='vid'>
    <video  loop autoPlay muted>
      <source src="http://fed-padel.fedeportes.com/fondo.mp4" type="video/mp4">
      </source>
    </video>
    </div>
      <Header navPosition="right" className="reveal-from-bottom" />
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/nosotros" component={Nosotros} layout={Nosotros} />
          <AppRoute exact path="/contacto" component={Contacto} layout={Contacto} />
          <AppRoute exact path="/login/:tipo" component={Login} layout={Login} />
          <AppRoute exact path="/home" component={Home} layout={Home} />
          <AppRoute exact path="/reservas" component={MisPedidos} layout={MisPedidos} />
          <AppRoute exact path="/horario" component={Horario} layout={Horario} />
          <AppRoute exact path="/" component={Calendario} layout={Calendario} />
        </Switch>
      )} />
    <Footer />
  
    </>
  );
}

export default App;