import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Logo from './partials/Logo';
import Image from '../../components/elements/Image';

import '../../App.css'
import FooterSocial from './partials/FooterSocial';
const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}


const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  console.log(user)
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });  

  const openMenu = () => {
    nav.current.className="header-nav mostrar"
    document.body.classList.add('off-nav-is-active');
    setIsactive(true);
  }

  const closeMenu = () => {
    nav.current.className="header-nav ocultar"
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }  
  const cerrar = (e) => {
    window.localStorage.removeItem("user");
    window.location.href="/"
  }  

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );
  

  return (
    <>
    <header
      {...props}
      className={classes}
    >
      <div>
        <div className={
          classNames(
            'site-header-inner',
            'extra',
            bottomDivider && 'has-bottom-divider'
          )}>
            <a href="/">
               <Logo/>
             </a>
          {!hideNav &&
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                     <div className="header-nav-inner">
                  <ul className={
                    classNames(
                      'list-reset text-xs',
                      'ulst',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                     
                      {
                      user ?(
                        <>
                        {
                          user.idrol_fk==1?(<>
                          <li className="ulsta">
                          <a href="/horario">{user.nombre}</a>
                        </li>
                          </>):(<>
                            <li className="ulsta">
                          <a>{user.nombre}</a>
                        </li>
                          </>)
                        }
                        
                        </>
                      ):(<>
                      </>)
                    }
                    <li className="ulsta" >
                      <a  href="/home">Home</a>
                    </li>
                    <li className="ulsta" >
                      <a  href="/">Reservas</a>
                    </li>
                    {
                      user ?(
                        <>
                        <li className="ulsta">
                           <a href="#" onClick={cerrar} >
                              Cerrar Sesion
                            </a>
                        </li>
                        </>
                      ):(
                        <>
                        <li className="ulsta">
                           <a href="/login/login" >
                              Iniciar Sesion
                            </a>
                        </li>
                        </>
                      )
                    }
                  </ul>
                </div>
              </nav>
            </>}
        </div>
      </div>
    </header>
    </>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
