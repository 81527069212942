import React from 'react';
// import sections
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, Link } from "react-router-dom";
import MyCalendar from '../layouts/Calender';
import {Button, Modal, ModalHeader,ModalBody,ModalFooter, FormGroup, Input, Label} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.css'
import Constantes from "./Constantes";
import Hero from '../components/sections/Hero';
import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';

class Horario extends React.Component{
  constructor(props) {
    super(props);
  this.state={
    du:"",
    dd:"",
    dt:"",
    dc:"",
    dci:"",
    ds:"",
    dsi:"",
    diau:"",
    diad:"",
    diat:"",
    diacu:"",
    diaci:"",
    diase:"",
    diasi:"",
    chl:false,
    chlu:false,
    chld:false,
    timeinl:"",
    timefinl:"",
    especialidadl:"",
    chm:false,
    chmu:false,
    chmd:false,
    timeinm:"",
    timefinm:"",
    especialidadm:"",
    chmi:false,
    chmiu:false,
    chmid:false,
    timeinmi:"",
    timefinmi:"",
    especialidadmi:"",
    chj:false,
    chju:false,
    chjd:false,
    timeinj:"",
    timefinj:"",
    especialidadj:"",
    chv:false,
    chvu:false,
    chvd:false,
    timeinv:"",
    timefinv:"",
    especialidadv:"",
    chs:false,
    chsu:false,
    chsd:false,
    timeins:"",
    timefins:"",
    especialidads:"",
    chd:false,
    chdu:false,
    chdd:false,
    timeind:"",
    timefind:"",
    especialidadd:"",
    tipoentrel:"",
    profesorl:"",
    tipoentrem:"",
    profesorm:"",
    tipoentremi:"",
    profesormi:"",
    tipoentrej:"",
    profesorj:"",
    tipoentrev:"",
    profesorv:"",
    tipoentres:"",
    profesors:"",
    tipoentred:"",
    profesord:"",
    user:null,
    obs1:"",
    obs2:"",
    obs3:"",
    obs4:"",
    obs5:"",
    obs6:"",
    obs7:"",
  }
  this.manejarCambio= this.manejarCambio.bind(this)
  this.save = this.save.bind(this);
}


componentDidMount(){
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  if(user !=null){
    this.setState({
      user:user,
    });
  }
  const diasds = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  var today = new Date();
  var dias = 1; // Número de días a agregar
  var fechas=["","","","","","",""];
  var mdias=["","","","","","",""];
  for (var i = 1; i < 8; i++) {
    today.setDate(today.getDate() + dias);
    var mes=parseInt(today.getMonth())+1;
    if(mes<10){
      mes="0"+mes;
    }
    var dia= today.getDate();
    if(dia<10){
      dia="0"+dia;
    }
    fechas[i-1]=today.getFullYear()+"-"+mes+"-"+dia;
    mdias[i-1]=diasds[today.getDay()];
  }
  this.setState({
    du:mdias[0],
    dd:mdias[1],
    dt:mdias[2],
    dc:mdias[3],
    dci:mdias[4],
    ds:mdias[5],
    dsi:mdias[6],
    diau:fechas[0],
    diad:fechas[1],
    diat:fechas[2],
    diacu:fechas[3],
    diaci:fechas[4],
    diase:fechas[5],
    diasi:fechas[6],
  })
}

  manejarCambio(e) {
    // Extraer la clave del estado que se va a actualizar, así como el valor
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value, error: "" })
    console.log(e.target.id+e.target.value)
}
  async save(){
    if(this.state.chl==true){
      if(this.state.timeinl=="" || this.state.timefinl=="" || (this.state.chlu==false && this.state.chld==false)|| this.state.tipoentrel=="" || this.state.profesorl==""){
        window.alert("Campos vacios. Porfavor verifique que los campos esten completos en el dia "+this.state.du );
      }else{
        console.log(this.state.du)
        if(this.state.chlu==true){
          this.Guardar(this.state.diau,this.state.timeinl,this.state.timefinl,this.state.tipoentrel,this.state.profesorl,"Cancha 1",this.state.obs1);
        } if(this.state.chld==true){
          this.Guardar(this.state.diau,this.state.timeinl,this.state.timefinl,this.state.tipoentrel,this.state.profesorl,"Cancha 2",this.state.obs1);
        }
      }
    }
    if(this.state.chm==true){
      if(this.state.timeinm=="" || this.state.timefinm=="" || (this.state.chmu==false && this.state.chmd==false)|| this.state.tipoentrem=="" || this.state.profesorm==""){
        window.alert("Campos vacios. Porfavor verifique que los campos esten completos en el dia "+this.state.dd );
      }else{
        if(this.state.chmu==true){
          this.Guardar(this.state.diad,this.state.timeinm,this.state.timefinm,this.state.tipoentrem,this.state.profesorm,"Cancha 1",this.state.obs2);
        } if(this.state.chmd==true){
          this.Guardar(this.state.diad,this.state.timeinm,this.state.timefinm,this.state.tipoentrem,this.state.profesorm,"Cancha 2",this.state.obs2);
        }
      }
    }
    if(this.state.chmi==true){
      if(this.state.timeinmi=="" || this.state.timefinmi=="" || (this.state.chmiu==false && this.state.chmid==false)|| this.state.tipoentremi=="" || this.state.profesormi==""){
        window.alert("Campos vacios. Porfavor verifique que los campos esten completos en el dia "+this.state.dt );
      }else{
        console.log(this.state.dt)
        if(this.state.chmiu==true){
          this.Guardar(this.state.diat,this.state.timeinmi,this.state.timefinmi,this.state.tipoentremi,this.state.profesormi,"Cancha 1",this.state.obs3);
        } if(this.state.chmid==true){
          this.Guardar(this.state.diat,this.state.timeinmi,this.state.timefinmi,this.state.tipoentremi,this.state.profesormi,"Cancha 2",this.state.obs3);
        }
      }
    }
    if(this.state.chj==true){
      console.log(this.state.dc)
      if(this.state.timeinj=="" || this.state.timefinj=="" || (this.state.chju==false && this.state.chjd==false)|| this.state.tipoentrej=="" || this.state.profesorj==""){
        window.alert("Campos vacios. Porfavor verifique que los campos esten completos en el dia "+this.state.dc );
      }else{
        if(this.state.chju==true){
          this.Guardar(this.state.diacu,this.state.timeinj,this.state.timefinj,this.state.tipoentrej,this.state.profesorj,"Cancha 1",this.state.obs4);
        } if(this.state.chjd==true){
          this.Guardar(this.state.diacu,this.state.timeinj,this.state.timefinj,this.state.tipoentrej,this.state.profesorj,"Cancha 2",this.state.obs4);
        }
      }
    }
    if(this.state.chv==true){
      if(this.state.timeinv=="" || this.state.timefinv=="" || (this.state.chvu==false && this.state.chvd==false)|| this.state.tipoentrev=="" || this.state.profesorv==""){
        window.alert("Campos vacios. Porfavor verifique que los campos esten completos en el dia "+this.state.dci );
      }else{
        console.log(this.state.dci)
        if(this.state.chvu==true){
          this.Guardar(this.state.diaci,this.state.timeinv,this.state.timefinv,this.state.tipoentrev,this.state.profesorv,"Cancha 1",this.state.obs5);
        } if(this.state.chvd==true){
          this.Guardar(this.state.diaci,this.state.timeinv,this.state.timefinv,this.state.tipoentrev,this.state.profesorv,"Cancha 2",this.state.obs5);
        }
      }
    }
    if(this.state.chs==true){
      if(this.state.timeins=="" || this.state.timefins=="" || (this.state.chsu==false && this.state.chsd==false)|| this.state.tipoentres=="" || this.state.profesors==""){
        window.alert("Campos vacios. Porfavor verifique que los campos esten completos en el dia "+this.state.ds );
      }else{
      console.log(this.state.ds)
        if(this.state.chsu==true){
          this.Guardar(this.state.diase,this.state.timeins,this.state.timefins,this.state.tipoentres,this.state.profesors,"Cancha 1",this.state.obs6);
        } if(this.state.chsd==true){
          this.Guardar(this.state.diase,this.state.timeins,this.state.timefins,this.state.tipoentres,this.state.profesors,"Cancha 2",this.state.obs6);
        }
      }
    }
    if(this.state.chd==true){
      if(this.state.timeind=="" || this.state.timefind=="" || (this.state.chdu==false && this.state.chdd==false)|| this.state.tipoentred=="" || this.state.profesord==""){
        window.alert("Campos vacios. Porfavor verifique que los campos esten completos en el dia "+this.state.dsi );
      }else{
        console.log(this.state.dsi)
        if(this.state.chdu==true){
          this.Guardar(this.state.diasi,this.state.timeind,this.state.timefind,this.state.tipoentred,this.state.profesord,"Cancha 1",this.state.obs7);
        } if(this.state.chdd==true){
          this.Guardar(this.state.diasi,this.state.timeind,this.state.timefind,this.state.tipoentred,this.state.profesord,"Cancha 2",this.state.obs7);
        }
      }
    }
  }

  async Guardar(fechaprueba,timeinprueba,timefinprueba,tipoprueba,profesorprueba,canchaprueba,observ){
    // Codificar nuestro videojuego como JSON
    let user = localStorage.getItem("user");
    user = user ? JSON.parse(user) : null;
    const cliente=user.id;
    const contacto=user.contacto;
    const especialidad=canchaprueba;
    const fecha=fechaprueba;
    const observacion=observ;
    var timein=timeinprueba;
    var timefin=timefinprueba;  
    const profesor= profesorprueba;
    const tipoentre= tipoprueba;
    
    var horainicio=timein.split(":");
    var horafin= timefin.split(":");
   if(horainicio[1]=="59"){
      horainicio[1]="00";
      horainicio[0]=parseInt(horainicio[0])+1;
      if(horainicio[0]<10){
        horainicio[0]="0"+horainicio[0]
      }
   }else{
    horainicio[1]=parseInt(horainicio[1])+1;
    if(horainicio[1]<10){
      horainicio[1]="0"+horainicio[1]
    }
  }
  if(horafin[1]=="00"){
    horafin[1]="59";
    horafin[0]=parseInt(horafin[0])-1;
    if(horafin[0]<10){
      horafin[0]="0"+horafin[0]
    }
  }else{
    horafin[1]=parseInt(horafin[1])-1;
    if(horafin[1]<10){
      horafin[1]="0"+horafin[1]
    }
  } 
  var timeina=horainicio[0]+":"+horainicio[1];
  var timefina= horafin[0]+":"+horafin[1];
  const verfcita = await fetch(`${Constantes.RUTA_API}/verificar_cita.php?fecha=${fecha}&&fechain=${timeina}&&cancha=${especialidad}&&fechafin=${timefina}`);
  const saber = await verfcita.json();
  if(!saber){
    const respuesta = await fetch(`${Constantes.RUTA_API}/guardar_cita.php?reserva=${cliente}&&cliente=${cliente}&&contacto=${contacto}&&especialidad=${especialidad}&&fecha=${fecha}&&timein=${timein}&&timefin=${timefin}&&profesor=${profesor}&&tipo=${tipoentre}&&observacion=${observacion}`);
    const exitoso = await respuesta.json();
    if (exitoso) {
      window.alert("Su reserva se guardo correctamente el dia: "+fecha+" a las: "+timein+ " en la "+especialidad );
    } else {
      window.alert("No se pudo agendar su reservación en la fecha: "+fecha+". Intentelo mas tarde")
    }
  }else{
    window.alert("La "+especialidad+" no se encuentra disponible en la fecha "+fecha +" en el horario "+ timein +" - "+timefin +". Por favor realice un reserva en otro horario")
  }      
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(e.target.name+e.target.value)
  }

  render(){
    let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
    return (
      <>
      {user?(
        <>
        {user.idrol_fk==1?(
          <>
          <main className="site-content">
       <Hero className="illustration-section-01" />
       <Col>
        <CardHeader>Reservar
        </CardHeader>
        <CardBody>
        <div className="btnreserva">
           <Button color="success" onClick={(this.save)}>Reservar</Button>
         </div>
         <br/>
            <Table responsive>
            <thead >
                <tr >
                <th style={{textAlign:"center"}}>Dia</th>
                <th style={{textAlign:"center"}}>Selec</th>
                <th style={{textAlign:"center"}}>Hora Inicio</th>
                <th style={{textAlign:"center"}}>Hora Fin</th>
                <th style={{textAlign:"center"}}>C 1</th>
                <th style={{textAlign:"center"}}>C 2</th>
                <th style={{textAlign:"center"}}>Grupo</th>
                <th style={{textAlign:"center"}}>Profesor</th>
                <th style={{textAlign:"center"}}>Observacion</th>
                </tr>
            </thead>
            <tbody>
                <tr style={{textAlign:"center"}} >
                <td>{this.state.du + ": "+ this.state.diau}</td>
                <td >
                <input type="checkbox" id="chl" ref="chl" defaultChecked={this.state.chl}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
  }}></input>
                </td>
                <td> 
                <Input type="time" id="timeinl" onChange={this.manejarCambio} value={this.state.timeinl} />
                </td>
                <td> 
                <Input type="time" id="timefinl" onChange={this.manejarCambio} value={this.state.timefinl} />
                </td>
                <td>
                <input type="checkbox" id="chlu" ref="chlu" defaultChecked={this.state.chlu}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
                <td>
                <input type="checkbox" id="chld" ref="chld" defaultChecked={this.state.chld}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
              <td>
              <select className="form-control selectable" id="tipoentrel" onChange={this.manejarCambio} value={this.state.tipoentrel} >
              <option></option>
                <option>Azul</option>
                <option>Rojo</option>
                <option>Ejecutivo</option>
                <option>Juveniles</option>
              </select>
              </td>
              <td>
              <select className="form-control selectable" id="profesorl" onChange={this.manejarCambio} value={this.state.profesorl} >
              <option></option>
                <option>Nombre 1</option>
                <option>Nombre 2</option>
                <option>Nombre 3</option>
              </select>
              </td>
              <td>
              <Input type="text" id="obs1" onChange={this.manejarCambio} value={this.state.obs1} />
              </td>
                </tr>
                <tr style={{textAlign:"center"}}>
                <td>{this.state.dd + ": "+ this.state.diad}</td>
                <td style={{textAlign:"center"}}>
                <input type="checkbox" id="chm" ref="chm" defaultChecked={this.state.chm}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
                <td> 
                <Input type="time" id="timeinm" onChange={this.manejarCambio} value={this.state.timeinm} />
                </td>
                <td> 
                <Input type="time" id="timefinm" onChange={this.manejarCambio} value={this.state.timefinm} />
                </td>
                <td>
                <input type="checkbox" id="chmu" ref="chmu" defaultChecked={this.state.chmu}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
                <td>
                <input type="checkbox" id="chmd" ref="chmd" defaultChecked={this.state.chmd}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
              <td>
              <select className="form-control selectable" id="tipoentrem" onChange={this.manejarCambio} value={this.state.tipoentrem} >
              <option></option>
                <option>Azul</option>
                <option>Rojo</option>
                <option>Ejecutivo</option>
                <option>Juveniles</option>
              </select>
              </td>
              <td>
              <select className="form-control selectable" id="profesorm" onChange={this.manejarCambio} value={this.state.profesorm} >
              <option></option>
                <option>Nombre 1</option>
                <option>Nombre 2</option>
                <option>Nombre 3</option>
              </select>
              </td>
              <td>
              <Input type="text" id="obs2" onChange={this.manejarCambio} value={this.state.obs2} />
              </td>
                </tr>
                <tr style={{textAlign:"center"}}>
                <td>{this.state.dt + ": "+ this.state.diat}</td>
                <td style={{textAlign:"center"}}>
                <input type="checkbox" id="chmi" ref="chmi" defaultChecked={this.state.chmi}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
  }}></input>
                </td>
                <td> 
                <Input type="time" id="timeinmi" onChange={this.manejarCambio} value={this.state.timeinmi} />
                </td>
                <td> 
                <Input type="time" id="timefinmi" onChange={this.manejarCambio} value={this.state.timefinmi} />
                </td>
                <td>
                <input type="checkbox" id="chmiu" ref="chmiu" defaultChecked={this.state.chmiu}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
                <td>
                <input type="checkbox" id="chmid" ref="chmid" defaultChecked={this.state.chmid}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
              <td>
              <select className="form-control selectable" id="tipoentremi" onChange={this.manejarCambio} value={this.state.tipoentremi} >
              <option></option>
                <option>Azul</option>
                <option>Rojo</option>
                <option>Ejecutivo</option>
                <option>Juveniles</option>
              </select>
              </td>
              <td>
              <select className="form-control selectable" id="profesormi" onChange={this.manejarCambio} value={this.state.profesormi} >
              <option></option>
                <option>Nombre 1</option>
                <option>Nombre 2</option>
                <option>Nombre 3</option>
              </select>
              </td>
              <td>
              <Input type="text" id="obs3" onChange={this.manejarCambio} value={this.state.obs3} />
              </td>
                </tr>
                <tr style={{textAlign:"center"}}>
                <td>{this.state.dc + ": "+ this.state.diacu}</td>
                <td style={{textAlign:"center"}}>
                <input type="checkbox" id="chj" ref="chj" defaultChecked={this.state.chj}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
  }}></input>
                </td>
                <td> 
                <Input type="time" id="timeinj" onChange={this.manejarCambio} value={this.state.timeinj} />
                </td>
                <td> 
                <Input type="time" id="timefinj" onChange={this.manejarCambio} value={this.state.timefinj} />
                </td>
                <td>
                <input type="checkbox" id="chju" ref="chju" defaultChecked={this.state.chju}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
                <td>
                <input type="checkbox" id="chjd" ref="chjd" defaultChecked={this.state.chjd}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
              <td>
              <select className="form-control selectable" id="tipoentrej" onChange={this.manejarCambio} value={this.state.tipoentrej} >
              <option></option>
                <option>Azul</option>
                <option>Rojo</option>
                <option>Ejecutivo</option>
                <option>Juveniles</option>
              </select>
              </td>
              <td>
              <select className="form-control selectable" id="profesorj" onChange={this.manejarCambio} value={this.state.profesorj} >
              <option></option>
                <option>Nombre 1</option>
                <option>Nombre 2</option>
                <option>Nombre 3</option>
              </select>
              </td>
              <td>
              <Input type="text" id="obs4" onChange={this.manejarCambio} value={this.state.obs4} />
              </td>
                </tr>
                <tr style={{textAlign:"center"}}>
                <td>{this.state.dci + ": "+ this.state.diaci}</td>
                <td style={{textAlign:"center"}}>
                <input type="checkbox" id="chv" ref="chv" defaultChecked={this.state.chv}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
  }}></input>
                </td>
                <td> 
                <Input type="time" id="timeinv" onChange={this.manejarCambio} value={this.state.timeinv} />
                </td>
                <td> 
                <Input type="time" id="timefinv" onChange={this.manejarCambio} value={this.state.timefinv} />
                </td>
                <td>
                <input type="checkbox" id="chvu" ref="chvu" defaultChecked={this.state.chvu}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
                <td>
                <input type="checkbox" id="chvd" ref="chvd" defaultChecked={this.state.chvd}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
              <td>
              <select className="form-control selectable" id="tipoentrev" onChange={this.manejarCambio} value={this.state.tipoentrev} >
              <option></option>
                <option>Azul</option>
                <option>Rojo</option>
                <option>Ejecutivo</option>
                <option>Juveniles</option>
              </select>
              </td>
              <td>
              <select className="form-control selectable" id="profesorv" onChange={this.manejarCambio} value={this.state.profesorv} >
              <option></option>
                <option>Nombre 1</option>
                <option>Nombre 2</option>
                <option>Nombre 3</option>
              </select>
              </td>
              <td>
              <Input type="text" id="obs5" onChange={this.manejarCambio} value={this.state.obs5} />
              </td>
                </tr>
                <tr style={{textAlign:"center"}}>
                <td>{this.state.ds + ": "+ this.state.diase}</td>
                <td style={{textAlign:"center"}}>
                <input type="checkbox" id="chs" ref="chs" defaultChecked={this.state.chs}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
  }}></input>
                </td>
                <td> 
                <Input type="time" id="timeins" onChange={this.manejarCambio} value={this.state.timeins} />
                </td>
                <td> 
                <Input type="time" id="timefins" onChange={this.manejarCambio} value={this.state.timefins} />
                </td>
                <td>
                <input type="checkbox" id="chsu" ref="chsu" defaultChecked={this.state.chsu}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
                <td>
                <input type="checkbox" id="chsd" ref="chsd" defaultChecked={this.state.chsd}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
              <td>
              <select className="form-control selectable" id="tipoentres" onChange={this.manejarCambio} value={this.state.tipoentres} >
              <option></option>
                <option>Azul</option>
                <option>Rojo</option>
                <option>Ejecutivo</option>
                <option>Juveniles</option>
              </select>
              </td>
              <td>
              <select className="form-control selectable" id="profesors" onChange={this.manejarCambio} value={this.state.profesors} >
              <option></option>
                <option>Nombre 1</option>
                <option>Nombre 2</option>
                <option>Nombre 3</option>
              </select>
              </td>
              <td>
              <Input type="text" id="obs6" onChange={this.manejarCambio} value={this.state.obs6} />
              </td>
                </tr>
                <tr style={{textAlign:"center"}}>
                <td>{this.state.dsi + ": "+ this.state.diasi}</td>
                <td style={{textAlign:"center"}}>
                <input type="checkbox" id="chd" ref="chd" defaultChecked={this.state.chd}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
                <td> 
                <Input type="time" id="timeind" onChange={this.manejarCambio} value={this.state.timeind} />
                </td>
                <td> 
                <Input type="time" id="timefind" onChange={this.manejarCambio} value={this.state.timefind} />
                </td>
                <td>
                <input type="checkbox" id="chdu" ref="chdu" defaultChecked={this.state.chdu}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
                <td>
                <input type="checkbox" id="chdd" ref="chdd" defaultChecked={this.state.chdd}  
                onChange={(e) => {
                  this.handleChange({
                    target: {
                      name: e.target.id,
                      value: e.target.checked,
                    },
                  });
                }}></input>
                </td>
              <td>
              <select className="form-control selectable" id="tipoentred" onChange={this.manejarCambio} value={this.state.tipoentred} >
              <option></option>
                <option>Azul</option>
                <option>Rojo</option>
                <option>Ejecutivo</option>
                <option>Juveniles</option>
              </select>
              </td>
              <td>
              <select className="form-control selectable"  id="profesord" onChange={this.manejarCambio} value={this.state.profesord} >
              <option></option>
                <option>Nombre 1</option>
                <option>Nombre 2</option>
                <option>Nombre 3</option>
              </select>
              </td>
              <td>
              <Input type="text" id="obs7" onChange={this.manejarCambio} value={this.state.obs7} />
              </td>
                </tr>
             
            </tbody>
            </Table>
       
        </CardBody>
        </Col>
      
      </main>
     </>
        ):(
          Redirect("/")
        )}
     </>
      ):(
        Redirect("/")
      )}
      </>
    );
  }
}

export default Horario;
  