import React, { Component } from "react";
import { Redirect} from "react-router-dom";
import {Button, Modal, ModalHeader,ModalBody,ModalFooter, FormGroup, Input, Label} from 'reactstrap'
import Hero from '../components/sections/Hero';
import Constantes from "../views/Constantes";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email:"",
      passwordrep:"",
      passwordc:"",
      numero:"",
      nombre:"",
      user:null,
      abierto:false,
      abiertosign:false
    };
    this.save = this.save.bind(this);
    this.close = this.close.bind(this);
    this.opencrear= this.opencrear.bind(this);
    this.crear= this.crear.bind(this);
  }

  handleChange = e => {
      this.setState({ [e.target.id]: e.target.value, error: "" });
    }

  componentDidMount(){
    let user = localStorage.getItem("user");
    user = user ? JSON.parse(user) : null;
    
    const tip=this.props.children.props.match.params.tipo;
    console.log(tip)
    if(tip=="login"){
      this.setState({
        abierto:true
      })
      this.setState({
        user
      })
    }else{
      this.setState({
        abiertosign:true
      })
    }
    
  }

  async save(){
    // Codificar nuestro videojuego como JSON
    const cliente=this.state.username;
    const pass = this.state.password;
    // ¡Y enviarlo!
    if(cliente=="" || pass==""){
      window.alert("Ingresar correo y contraseña.");
      return false;
    }
   
    const verfcita = await fetch(`${Constantes.RUTA_API}/loguear.php?name=${cliente}&&password=${pass}`);
    const saber = await verfcita.json();
    if(!saber){
        window.alert("Nombre de usuario o contrasena incorrecta.")
        this.setState({
            username:"",
            password:""
        })
    }else{
      window.localStorage.setItem("user",JSON.stringify(saber));
      this.setState({
            user:saber
          });
    }
  }

  async crear(){
    // Codificar nuestro videojuego como JSON
    const cliente=this.state.nombre;
    const pass = this.state.passwordc;
    const correo = this.state.email;
    console.log(correo)
    const numero = this.state.numero;
    const paassr= this.state.passwordrep
    if(cliente==""||pass==""||correo==""||numero==""||paassr==""){
      window.alert("Porfavor verificar que todos los campos esten llenos");
      return false;
    }
    if(numero.length!=10){
      window.alert("La longitud del número de teléfono es incorrecta por favor ingreselo nuevamente. Recuerde que mediante este número se le informara la confirmación de su reserva");
      return false;
    }
    if(pass!=paassr){
      window.alert("Contrasenas no coinciden");
      return false;
    }
    const verfuser = await fetch(`${Constantes.RUTA_API}/verificar_usuario.php?correo=${correo}`);
    const saberuser = await verfuser.json();
    if(saberuser){
      window.alert("Ya existe un usuario registrado con esa direccion de correo.");
      return false;
    }
    // ¡Y enviarlo!
    const verfcita = await fetch(`${Constantes.RUTA_API}/crear_usuario.php?nombre=${cliente}&&contrasena=${pass}&&contacto=${numero}&&rol=4&&correo=${correo}`);
    const saber = await verfcita.json();
    if(saber){
        window.alert("Usuario creado correctamente porfavor inicie sesion.")
        window.location.href="/login/login"
    }
  }

  close(){
    window.location.href="/"
  }
  opencrear(){
    this.setState({
      abiertosign:!this.state.abiertosign
    })
  }

  render() {
    const modalStyle={
        position:"absolute",
        top:"50%",
        left:"50%",
        transform:"translate(-50%,-50%)"
      }
    return !this.state.user ? (
      <>
       <main className="site-content">
      </main>
           <Modal isOpen={this.state.abierto} style={modalStyle}>
          <ModalHeader>
            Iniciar Sesion
          </ModalHeader>
          <ModalBody>
          <Label for="usuario">Correo</Label>
              <Input type="text" id="username" placeholder="Ingresar Correo" onChange={(this.handleChange)} value={this.state.cliente} />
            <Label for="usuario">Contraseña</Label>
              <Input type="password" id="password" placeholder="Ingresar Contraseña" onChange={this.handleChange} value={this.state.contacto} />
            
          </ModalBody>
          <ModalFooter>
          <Button color="success" onClick={(this.save)}>Login</Button>
          <Button color="success" onClick={(this.opencrear)}>Crear Cuenta</Button>
            <Button color="danger" onClick={(this.close)}>Cancelar</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.abiertosign} style={modalStyle}>
          <ModalHeader>
            Crear Cuenta
          </ModalHeader>
          <ModalBody>
          <Label for="usuario">Nombre Completo</Label>
          <Input type="text" id="nombre" placeholder="Ingresar Nombre Completo" onChange={(this.handleChange)} value={this.state.nombre} />
          <Label for="usuario">Correo</Label>
          <Input type="email" id="email" placeholder="Ingresar Correo" onChange={this.handleChange} value={this.state.email} />
          <Label for="usuario">Número de contacto</Label>
          <Input type="number" id="numero" placeholder="Ingresar Número de contacto" onChange={(this.handleChange)} value={this.state.numero} />
          <Label for="usuario">Contraseña</Label>
          <Input type="password" id="passwordc" placeholder="Ingresar Contraseña" onChange={this.handleChange} value={this.state.passwordc} />
          <Input type="password" id="passwordrep" placeholder="Repetir Contraseña" onChange={this.handleChange} value={this.state.passwordrep} />
            
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={(this.crear)}>Crear </Button>
            <Button color="danger" onClick={(this.opencrear)}>Cancelar</Button>
          </ModalFooter>
        </Modal>
   
     </>
    ) : (
      <Redirect to="/" />
    );
  }
}

export default Login;