import React from 'react';
// import sections
import 'react-toastify/dist/ReactToastify.css';
import {Button, Modal, ModalHeader,ModalBody,ModalFooter, FormGroup, Input, Label} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.css'
import Constantes from "./Constantes";
import Hero from '../components/sections/Hero';
import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import defaultimg from '../assets/images/pago.jpg'

class MisPedidos extends React.Component{
  constructor(props) {
    super(props);
  this.state={
    abierto:false,
    abiertopago:false,
    cliente:"",
    clienteid:"",
      contacto:"",
      especialidad:"",
      fecha:"",
      hora:"",
      user:null,
      abierto:false,
    clienteid:"0",
    estado:"0",
    evento:"0",
    profesor:"bloqueado",
    tipoentre:"",
    timein:"",
      timefin:"",
      citas:[],
      id:"",
      picture:defaultimg,
      fileup: null,
      claseu:"jss72",
      clased:"jss73",
      claset:"jss74",
  }
  this.manejarCambio= this.manejarCambio.bind(this)
  this.mostrar= this.mostrar.bind(this);
  this.delete = this.delete.bind(this);
}
  manejarCambio(e) {
    // Extraer la clave del estado que se va a actualizar, así como el valor
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value, error: "" })
}

  abrirModal=()=>{
    this.setState({
      abierto:!this.state.abierto,
      especialidad:"",
      fecha:"",
      hora:""
    });
  }

  mostrar=(cita)=>{
    if(cita.estado==1){
      this.state.claseu="jss73";
      this.state.clased="jss74";
      this.state.claset="jss72";
    }else{
      this.state.claseu="jss73";
      this.state.clased="jss72";
      this.state.claset="jss74";
    }
        this.setState({
      abierto:!this.state.abierto,
      especialidad:cita.especialidad,
      fecha:cita.fecha,
      hora:cita.hora,
      timein:cita.start,
      timefin:cita.end,
      profesor:cita.profesor,
      tipoentre:cita.tipoentre,
      cliente:cita.cliente,
      contacto:cita.contacto,
            id:cita.id,
            clienteid:cita.clienteid,
            estado:cita.estado,
            pagoimage:cita.pagoimage,
            observacion:cita.observacion,
    });
  }
  async componentWillMount(){
    let user = localStorage.getItem("user");
    user = user ? JSON.parse(user) : null;
    if(user !=null){

      this.setState({
        user:user,
        cliente:user.nombre,
        contacto:user.contacto,
      });
          let date= new Date();
          let day = date.getDate()
          let month = date.getMonth() + 1
          let year = date.getFullYear()
          let hoy="";
          if(month < 10){
            hoy=year+"-0"+month+"-"+day;
          }else{
            hoy=year+"-"+month+"-"+day;
          }
          const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_reservas.php?fecha=${hoy}`);

          const citas = await respuesta.json();
          citas.forEach(element => {
            const fechahora=element.fecha.split(" ");
            const fin=fechahora[0];
            let cita=null;
            var mhora=element.timein.split(":");
            if(user.idrol_fk==parseInt("1")){
                cita={
            title:element.cliente+" telf:"+element.contacto+"-"+element.especialidad+"-"+element.profesor,
                    start:element.timein,
                    end:element.timefin,
                    cliente:element.reserva,
                    clienteid:element.cliente,
                    id:element.id,
                    contacto:element.contacto,
                    estado:element.confirmada,
                    especialidad:element.especialidad,
                    hora:mhora[0]+":"+mhora[1],
                    fecha:fin,
                    profesor:element.profesor,
                    tipoentre:element.tipo,
                    observacion:element.observacion,
                    pagoimage:"https://fed-padel.fedeportes.com/ver_imagen.php?id="+element.id
                  }
              }else{
                if(element.cliente==user.id){
                    cita={
                        title:"Mi Reserva. Cancha: "+element.especialidad,
                        start:element.timein,
                        end:element.timefin,
                        cliente:element.reserva,
                        clienteid:element.cliente,
                        id:element.id,
                        contacto:element.contacto,
                        estado:element.confirmada,
                        especialidad:element.especialidad,
                        hora:mhora[0]+":"+mhora[1],
                        fecha:fin,
                        profesor:element.profesor,
                        tipoentre:element.tipo,
                        pagoimage:"https://fed-padel.fedeportes.com/ver_imagen.php?id="+element.id
                      }
                }
            }
            if(cita){
              this.setState({
                citas: this.state.citas.concat(cita),
              });
            }
      });
    }
  }


  async delete(){
    // Codificar nuestro videojuego como JSON
    const id=this.state.id;
      const verfcita = await fetch(`${Constantes.RUTA_API}/eliminar_cita.php?id=${id}`);
      const saber = await verfcita.json();
      if(!saber){
        window.alert("No se pudo eliminar la reserva.")
      }else{
        window.alert("Esta reserva ha sido eliminada.")
        window.location.reload();
      }
  }
  render(){
    const citas=this.state.citas;
    const modalStyle={
      position:"absolute",
      top:"0%",
      left:"50%",
      transform:"translate(-50%,0%)"
    }
    const modalStyles={
      position:"absolute",
      top:"50%",
      left:"50%",
      transform:"translate(-50%,-50%)"
    }
    return (
      <>
       <main className="site-content">
       <Hero className="illustration-section-01" />
       <Col>
        <CardHeader>Mis Reservas</CardHeader>
        <CardBody>
            <Table responsive>
            <thead>
                <tr>
                <th>#</th>
                <th>User</th>
                <th>Contacto</th>
                <th>Fecha</th>
                <th>Hora Inicio</th>
                <th>Hora Fin</th>
                </tr>
            </thead>
            <tbody>
            {citas && citas.length ? (
              citas.map((cita, index) => (
                <tr key={index} >
                <td scope="row">
                <div className="is-clearfix">
                    <button onClick={() =>{
                         this.mostrar(cita)
                    }}
                    className="button is-small is-outlined is-primary is-pulled-right">
                      Select
                    </button>
                </div>
                    </td>
                 <td>{cita.cliente}</td>
                <td> {cita.contacto}</td>
                <td>{cita.fecha}</td>
                <td>{cita.start}</td>
                <td>{cita.end}</td>
                </tr>
              ))
            ):(
              <tr>
                <th scope="row">0</th>
                <td>No se encontraron reservas</td>
                </tr>
            )}
            </tbody>
            </Table>
        </CardBody>
        </Col>
      
        <Modal isOpen={this.state.abierto} style={modalStyle}>
       <ModalHeader>
      
              <div className="text-color-rojo">
              RESERVACIÓN
              </div>
              <div className="text-color-rojo">
              Costos por horario: <br/>
              De 6 am a 7:30 am $40 <br/>
              De 7:30 am a 18:00 $20<br/>
              De 18:00 am a 23:00 $40 
            </div>
          </ModalHeader>
          <ModalBody>
            {
              this.state.user ?(
                <>
               
                <FormGroup>
              <Label for="usuario">Nombre de usuario</Label>
              <Input type="text" id="cliente" onChange={(this.manejarCambio)} value={this.state.cliente} />
            </FormGroup>
            <FormGroup>
              <Label for="usuario">Número de Contacto</Label>
              <Input type="number" id="contacto" onChange={this.manejarCambio} value={this.state.contacto} />
            </FormGroup>
             <FormGroup>
              <Label for="usuario">Cancha</Label>
              <select className="form-control" style={{marginLeft:"30px"}} id="especialidad" onChange={this.manejarCambio} value={this.state.especialidad} >
              <option></option>
                <option>Cancha 1</option>
                <option>Cancha 2</option>
              </select>
            </FormGroup>
            <FormGroup>
            <Label for="usuario">Fecha</Label>
              <Input type="date" id="fecha" style={{marginLeft:"30px"}} onChange={this.manejarCambio} value={this.state.fecha} />
            
            </FormGroup>
            <FormGroup>
            <Label for="usuario">Hora</Label>
            <select className="form-control" style={{marginLeft:"30px"}} id="hora" onChange={this.manejarCambio} value={this.state.hora} >
              <option></option>
                <option>06:00</option>
                <option>07:30</option>
                <option>09:00</option>
                <option>10:30</option>
                <option>12:00</option>
                <option>13:30</option>
                <option>15:00</option>
                <option>16:30</option>
                <option>18:00</option>
                <option>19:30</option>
                <option>21:00</option>
                <option>22:30</option>
              </select>
            </FormGroup>
            
            {
              this.state.user.idrol_fk=="1" ?(
                <>
            <FormGroup>
              <Label for="usuario">Grupo de Entrenamiento</Label>
              <select className="form-control" style={{marginLeft:"30px"}} id="tipoentre" onChange={this.manejarCambio} value={this.state.tipoentre} >
              <option></option>
                <option>Azul</option>
                <option>Rojo</option>
                <option>Ejecutivo</option>
                <option>Juveniles</option>
              </select>
            </FormGroup>
            <FormGroup>
              <Label for="usuario">Profesor</Label>
              <select className="form-control" style={{marginLeft:"30px"}} id="profesor" onChange={this.manejarCambio} value={this.state.profesor} >
              <option></option>
                <option>Nombre 1</option>
                <option>Nombre 2</option>
                <option>Nombre 3</option>
              </select>
            </FormGroup>
            <FormGroup>
            <Label for="usuario">Observación</Label>
            <Input type="text" id="observacion" style={{marginLeft:"30px"}} onChange={this.manejarCambio} value={this.state.observacion} />
            </FormGroup>
            </>
              ):(
            <></>
            )}
                </>
              ):(
                <Label for="usuario">Por favor Inicia Sesión para agendar una reserva. Si no posees una cuenta crea una.</Label>
              )
            }
            
          </ModalBody>
          <ModalFooter>
            { this.state.user ? (
              <>
              {
                <>
                {this.state.user.idrol_fk=="1" &&(
                <>
                {
                this.state.evento=="1"? (
                  <></>
                ):(
                  <>
                    <Button color="danger" onClick={(this.delete)}>Eliminar</Button>
                  </>
                )}
                  
                  </>
                )}
              </>
              }
              {
                this.state.evento=="1"? (
                  <>
                  <Button color="success" onClick={(this.save)}>Agendar</Button>
                  </>
                ):(
                  <>
                  {
                    parseInt(this.state.clienteid)==this.state.user.id ?(
                      <>
                      { this.state.estado=="0" ? (
                        <>
                      {
                          this.state.user.idrol_fk!="1"? (
                            <>
                              <Button color="danger" onClick={(this.delete)}>Eliminar</Button>
                            </>
                          ):(
                            <>
                            </>
                          )}
                      </>
                      ):(
                        <></>
                      )}
                      </>
                    ):(
                      <></>
                    )
                  }
                  </>
                )
              }
              </>
            ):(
              <>
              <FormGroup>
              <Button color="success" href="/login/login">Iniciar Sesión</Button>
              <Button color="danger" href="/login/crear">Crear Cuenta</Button>
                </FormGroup>
              </>
            )
            }
              <Button color="danger" onClick={(this.abrirModal)}>Cerrar</Button>

          </ModalFooter>
        </Modal>
     
      </main>
      </>
    );
  }
}

export default MisPedidos;
  