import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../utils/SectionProps';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Image from '../components/elements/Image';
import Hero from '../components/sections/Hero';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const Contacto = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0 ',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  

  const sectionHeader = {
    title: 'CONTACTANOS',
    paragraph: ' HORARIOS DE ATENCION'
  };

  const sectionHeadera = {
    title: 'INFORMACIÓN PARA TRANSFERENCIAS',
    paragraph: ' La información que necesitas para realizar pagos mediante transferencias'
  };

  return (
      <>
      <main className="site-content ">
      <section
      className={outerClasses}
       >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content " />
          <div className={splitClasses}>
              <div className="features-tiles-item-content aling toppad">
              Dependiendo de las disposiciciones del COE nacional
              <br/>
              HORARIO NORMAL: LUNES A DOMINGO DE 6H00 - 23H00 <br/>
                <h4 className="mt-0 mb-8  "><b>CONTACTO </b></h4>
                  <p>
                    <b>Tel 1: </b>0984534849<br/>
                    <b>Tel 2: </b>0998200217<br/>
                    <b>Corrreo: </b> info@fedeportes.com
                  </p>
                  <h4 className="mt-0 mb-8  "><b>DIRECCIÓN </b></h4>
                  <p>
                    <b>Tumbaco </b> 
                  </p>
                </div>
              </div>
        </div>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeadera} className="center-content " />
          <div className={splitClasses}>
          <div className="features-tiles-item-content aling toppad">
                  <p>
                  <Image
                      src={require('../assets/images/contacto.jpeg')} 
                      alt="Features tile icon 01"
                      className="centrarimg"
                      width={500}
                      height={500} />      </p>
                </div>
            </div>
        </div>
      </div>
    </section>
   
      </main>
       </>
  );
}

Contacto.propTypes = propTypes;
Contacto.defaultProps = defaultProps;

export default Contacto;


