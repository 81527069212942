import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../utils/SectionProps';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Image from '../components/elements/Image';
import Hero from '../components/sections/Hero';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const Nosotros = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Quienes Somos',
    paragraph: 'Dental Elite ofrece una amplia gama de servicios odontológicos en las áreas de: Endodoncia, Periodoncia, Rehabilitación Oral, Odontopediatría, Operatoria Dental y Odontología Estética que se trabajan en conjunto con un equipo multidisciplinario que lo componen varios especialistas.'
  };

  return (
      <>
  <main className="site-content site-contenta">
    <Hero className="illustration-section-01" />
    </main>
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../assets/images/entrenador1.jpeg')}
                      alt="Features tile icon 01"
                      width={250}
                      height={250} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Salud Dental
                    </h4>
                  <p className="m-0 text-sm">
                  Cuida tu sonrisa 😁😁 y salud dental👄 junto a los mejores!! Seguimos con espectaculares descuentos!!! 🤑💸
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../assets/images/entrenador1.jpeg')}
                      alt="Features tile icon 02"
                      width={250}
                      height={250} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Endodoncia
                    </h4>
                  <p className="m-0 text-sm">
                  La Endodoncia es necesaria cuando las caries están en un estado avanzado y están afectando la pulpa del diente.
                    </p>
                </div>
              </div>
            </div>

          </div> 
        </div>

        <div id="flip-container">
          <div id="card1" class="card">
            <div class="front">Front</div>
            <div class="back"> Endodoncia</div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

Nosotros.propTypes = propTypes;
Nosotros.defaultProps = defaultProps;

export default Nosotros;